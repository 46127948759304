import { useInvokeCommandMutation } from '../api/packageActivation/packageActivationApiSlice';
import type { PackageAdminCommand } from '../api/packageActivation/types';
import { reportErrorToSentry } from '../../../configuration/setup/sentry';
import Notification from '@rio-cloud/rio-uikit/Notification';

export const useReQueuePackageActivationCommand = () => {
    const [invokeCommand] = useInvokeCommandMutation();

    return async (data: {
        accountId: string;
        assetId: string;
        packageCode: string;
    }) => {
        const invokeParams: PackageAdminCommand = {
            command: 'ReQueuePackageActivationCommand',
            payload: {
                accountId: data.accountId,
                assetId: data.assetId,
                packageCode: data.packageCode,
            },
        };

        try {
            await invokeCommand(invokeParams).unwrap();
            Notification.success('Successfully re-queued package activation');
        } catch (e) {
            reportErrorToSentry('Error re-queueing package activation', e);
            console.error(
                `Error re-queueing package activation for account ${data.accountId} and asset ${data.assetId}`,
                e
            );
            Notification.error(
                `Error occurred for account ${data.accountId} and asset ${data.assetId}. Details: ${String(e)}`,
                'Error re-queueing package activation',
                999999
            );
        }
    };
};
