import type React from 'react';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { useReQueuePackageActivationCommand } from './useReQueuePackageActivationCommand';

type ReQueuePackageActivationConfirmationDialogProps = {
    accountId: string;
    assetId: string;
    packageCode: string;
    show: boolean;
    onClickCancel: () => void;
    onClickConfirm: () => void;
};

const ReQueuePackageActivationConfirmationDialog: React.FC<ReQueuePackageActivationConfirmationDialogProps> = ({
    accountId,
    assetId,
    packageCode,
    show,
    onClickCancel,
    onClickConfirm,
}) => {
    const reQueuePackageActivation = useReQueuePackageActivationCommand();

    const handleConfirm = async () => {
        try {
            await reQueuePackageActivation({
                accountId: accountId,
                assetId: assetId,
                packageCode: packageCode,
            });

            onClickConfirm();
        } catch (e) {
            console.error('Error re-queueing package activation:', e);
        }
    };

    const content = (
        <div className='table-responsive'>
            <table className='table table-hover table-head-filled table-bordered'>
                <thead>
                    <tr>
                        {COLUMNS.map(column => (
                            <th key={column.id}>{column.label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr key={`dialog-${accountId}-${assetId}-${packageCode}`}>
                        <td>{accountId}</td>
                        <td>{assetId}</td>
                        <td>{packageCode}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    return (
        <ConfirmationDialog
            show={show}
            content={content}
            cancelButtonText='Abort'
            confirmButtonText={
                <>
                    <span className='rioglyph rioglyph-refresh margin-right-5' />
                    <span>RE-QUEUE PACKAGE-ACTIVATION</span>
                </>
            }
            onClickCancel={() => {
                onClickCancel();
            }}
            onClickConfirm={handleConfirm}
            title={'Please confirm the re-queueing'}
            useOverflow={true}
        />
    );
};

const COLUMNS = [
    { id: 'accountId', label: 'Account ID' },
    { id: 'assetId', label: 'Asset ID' },
    { id: 'packageCode', label: 'Package Code' },
];

export default ReQueuePackageActivationConfirmationDialog;
