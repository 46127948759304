import Select from '@rio-cloud/rio-uikit/Select';
import type { PackageActivationStatus } from '../api/packageActivation/types';

export type StatusOption = {
    id: string;
    label: PackageActivationStatus | 'ALL';
    selected: boolean;
};

export const STATUS_OPTIONS: StatusOption[] = [
    { id: '1', label: 'QUEUED' as PackageActivationStatus, selected: false },
    { id: '2', label: 'CREATED' as PackageActivationStatus, selected: false },
    { id: '3', label: 'FAILED' as PackageActivationStatus, selected: false },
    { id: '4', label: 'SUCCEEDED' as PackageActivationStatus, selected: false },
    { id: '5', label: 'REJECTED' as PackageActivationStatus, selected: true },
    { id: '6', label: 'CANCELLED' as PackageActivationStatus, selected: false },
    { id: '7', label: 'ALL', selected: false },
];

interface PackageActivationToolbarProps {
    handleAccountIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleResourceIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleStatusChange: (item: StatusOption) => void;
    setShowBookingIntentDialog: (value: boolean) => void;
}

export const PackageActivationTableToolbar = (props: PackageActivationToolbarProps) => {
    const { handleAccountIdChange, handleResourceIdChange, handleStatusChange, setShowBookingIntentDialog } = props;
    return (
        <div className='table-toolbar display-flex justify-content-center'>
            <div className='table-toolbar-container'>
                <div className='table-toolbar-group-left'>
                    <div className='table-toolbar-column'>
                        <label className='table-toolbar-label'>Account Id</label>
                        <div className='table-toolbar-search input-group'>
                            <span className='input-group-addon'>
                                <span className='rioglyph rioglyph-search' />
                            </span>
                            <div className='ClearableInput input-group'>
                                <input
                                    type='text'
                                    placeholder='Search account...'
                                    className='form-control'
                                    onChange={handleAccountIdChange}
                                />
                                <span className='clearButton hide'>
                                    <span className='clearButtonIcon rioglyph rioglyph-remove-sign' />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='table-toolbar-column'>
                        <label className='table-toolbar-label'>Asset Id</label>
                        <div className='table-toolbar-search input-group'>
                            <span className='input-group-addon'>
                                <span className='rioglyph rioglyph-search' />
                            </span>
                            <div className='ClearableInput input-group'>
                                <input
                                    type='text'
                                    placeholder='Search asset...'
                                    className='form-control'
                                    onChange={handleResourceIdChange}
                                />
                                <span className='clearButton hide'>
                                    <span className='clearButtonIcon rioglyph rioglyph-remove-sign' />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='table-toolbar-column'>
                        <label className='table-toolbar-label'>Status</label>
                        <div className='input-group' data-testid={'packageStateSelector'}>
                            <Select
                                id='status-select'
                                value={['3']}
                                options={STATUS_OPTIONS}
                                onChange={item => {
                                    if (item) {
                                        handleStatusChange(item);
                                    }
                                }}
                                className='min-width-150'
                            />
                        </div>
                    </div>
                </div>
                <div className='table-toolbar-group-right'>
                    <div className='table-toolbar-column'>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={() => setShowBookingIntentDialog(true)}
                        >
                            <span className='rioglyph rioglyph-plus-light' aria-hidden='true' />
                            <span className='text-capitalize'>Create booking intent</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
