import type { PackageActivation, PackageActivationStatus } from '../api/packageActivation/types';
import BookingIntentCreationDialog from './BookingIntentCreationDialog';
import { PackageActivationTableToolbar, STATUS_OPTIONS, type StatusOption } from './PackageActivationTableToolbar';
import ButtonDropdown from '@rio-cloud/rio-uikit/components/dropdown/ButtonDropdown';
import { useEffect, useState } from 'react';
import { useLazyGetPackageActivationsQuery } from '../api/packageActivation/packageActivationApiSlice';
import ReQueuePackageActivationConfirmationDialog from './ReQueuePackageActivationConfirmationDialog';

interface PackageActivationTableRowActionProps {
    packageActivation: PackageActivation;
}

const PackageActivationTableRowAction = (props: PackageActivationTableRowActionProps) => {
    const { accountId, assetId, status, packageCode } = props.packageActivation;
    const enableRequeueAction = status === 'FAILED' || status === 'REJECTED';
    const searchParams = new URLSearchParams({
        accountId,
        resourceIds: Array.isArray(assetId) ? assetId.join(',') : assetId,
    });
    const [showReQueuePackageActivationConfirmationDialog, setShowReQueuePackageActivationConfirmationDialog] =
        useState(false);

    const handleRedirectAction = () => {
        window.open(`/activation/activations?${searchParams.toString()}`, '_blank');
    };

    return (
        <>
            <ReQueuePackageActivationConfirmationDialog
                accountId={accountId}
                assetId={assetId}
                packageCode={packageCode}
                show={showReQueuePackageActivationConfirmationDialog}
                onClickCancel={() => setShowReQueuePackageActivationConfirmationDialog(false)}
                onClickConfirm={() => setShowReQueuePackageActivationConfirmationDialog(false)}
            />

            <ButtonDropdown
                className={'btn-s'}
                dropdownClassName={'overflow-auto'}
                title={<span className='rioglyph rioglyph-option-vertical' />}
                variant='link'
                iconOnly
                items={[
                    {
                        value: (
                            <div>
                                <span className='rioglyph rioglyph-search-list margin-right-5 text-size-18' />
                                View activations
                            </div>
                        ),
                        onSelect: handleRedirectAction,
                    },
                    {
                        value: (
                            <div>
                                <span className='rioglyph rioglyph-refresh margin-right-5 text-size-18' />
                                Requeue
                            </div>
                        ),
                        disabled: !enableRequeueAction,
                        onSelect: () => setShowReQueuePackageActivationConfirmationDialog(true),
                    },
                ]}
            />
        </>
    );
};

const HEADER_MESSAGE_IDS = [
    'Package ID',
    'Account ID',
    'Asset ID',
    'Package Code',
    'Status',
    'Operation Number',
    'Starts',
    'Ends',
    'Last Updated',
    'Succeeded',
    'Action',
];

export const PackageActivationTable = () => {
    const [fetchedPackageActivations, setFetchedPackageActivations] = useState<PackageActivation[]>([]);
    const [getPackageActivations, { isLoading, isSuccess, isError, data }] = useLazyGetPackageActivationsQuery();
    const [_, setStatusOptions] = useState(STATUS_OPTIONS);
    const [filters, setFilters] = useState<{
        accountId?: string;
        assetId?: string;
        status?: PackageActivationStatus;
    }>({ status: 'FAILED' });
    const [showBookingIntentDialog, setShowBookingIntentDialog] = useState(false);

    useEffect(() => {
        getPackageActivations(filters);
    }, [getPackageActivations, filters]);

    useEffect(() => {
        if (isSuccess && data) {
            setFetchedPackageActivations(data);
        }
    }, [isSuccess, data]);

    const handleAccountIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFilters({ ...filters, accountId: value });
    };

    const handleResourceIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFilters({ ...filters, assetId: value });
    };

    const handleStatusChange = (item: StatusOption) => {
        setFilters({ ...filters, status: item.label === 'ALL' ? undefined : item.label });

        const updatedStatusOptions = STATUS_OPTIONS.map(option => ({
            ...option,
            selected: option.id === item.id,
        }));
        setStatusOptions(updatedStatusOptions);
    };

    return (
        <>
            <BookingIntentCreationDialog
                show={showBookingIntentDialog}
                onClickCancel={() => setShowBookingIntentDialog(false)}
                onClickConfirm={() => setShowBookingIntentDialog(false)}
            />
            <PackageActivationTableToolbar
                handleAccountIdChange={handleAccountIdChange}
                handleResourceIdChange={handleResourceIdChange}
                handleStatusChange={handleStatusChange}
                setShowBookingIntentDialog={setShowBookingIntentDialog}
            />
            <div className='table-responsive'>
                <table className='table table-hover table-head-filled table-bordered'>
                    <thead>
                        <tr>
                            {HEADER_MESSAGE_IDS.map(id => (
                                <th key={id}>{id}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan={HEADER_MESSAGE_IDS.length}>Loading...</td>
                            </tr>
                        ) : isError ? (
                            <tr>
                                <td colSpan={HEADER_MESSAGE_IDS.length}>Error fetching package activations</td>
                            </tr>
                        ) : (
                            fetchedPackageActivations.map(packageActivation => (
                                <tr key={packageActivation.id}>
                                    <td>{packageActivation.id}</td>
                                    <td>{packageActivation.accountId}</td>
                                    <td>{packageActivation.assetId}</td>
                                    <td>{packageActivation.packageCode}</td>
                                    <td>{packageActivation.status}</td>
                                    <td>{packageActivation.bookingIntentOperationNumber}</td>
                                    <td>{packageActivation.startsAt}</td>
                                    <td>{packageActivation.endsAt}</td>
                                    <td>{packageActivation.lastUpdatedAt}</td>
                                    <td>{packageActivation.succeededAt ? 'Yes' : 'No'}</td>
                                    <td>
                                        <PackageActivationTableRowAction packageActivation={packageActivation} />
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export const PackageActivationTableContainer = PackageActivationTable;
